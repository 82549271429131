<template>
  <el-tabs style="height: 100%;" type="border-card" @tab-click="handleClick">
    <el-tab-pane label="小规模增值税及附加税费申报表">
      <div class="right_box">
        <el-button type="primary" @click="download()" size="small">导出</el-button>
        <el-button size="small" @click="printingNew()" type="success">打印</el-button>
      </div>
      <mainTable title="小规模增值税及附加税费申报表" ref="maintable" :startAccountPeriod="startAccountPeriod" :comId="comId"></mainTable>
    </el-tab-pane>
    <el-tab-pane label="增值税及附加税费申报表（小规模适用）附加资料（一）" v-if="showTable">
      <fbOne title="增值税及附加税费申报表（小规模适用）附加资料（一）" ref="fbOne"></fbOne>
    </el-tab-pane>
    <el-tab-pane label="增值税及附加税费申报表（小规模适用）附加资料（二）" v-if="showTable">
      <fbTwo title="增值税及附加税费申报表（小规模适用）附加资料（二）" ref="fbTwo"></fbTwo>
    </el-tab-pane>
    <el-tab-pane label="增值税减免税申报明细表">
      <reduction title="增值税减免税申报明细表" ref="fbThree" :startAccountPeriod="startAccountPeriod" :comId="comId"></reduction>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import mainTable from "./components/mainTable.vue"
import fbOne from "./components/fb1.vue"
import fbTwo from "./components/fb2.vue"
import reduction from "./components/reduction.vue" 
import { report ,exportReport} from "@/api/printSet"

export default {
  name:'smallVat',
  components:{
    mainTable,
    fbOne,
    fbTwo,
    reduction
  },
  data() {
    return {
      startAccountPeriod:'',
      comId:0,
      type:'',
      showTable:this.$store.getters["user/comInfo"].comKj,
      param:{
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:['gs_small_vat']
      }
    }
  },
  created(){
    if(this.$store.getters["commons/params"].type){
      this.type = this.$store.getters["commons/params"].type
      this.startAccountPeriod = this.$store.getters["commons/params"].startAccountPeriod
      this.comId = this.$store.getters["commons/params"].comId
    }
  },
  methods:{
    handleClick(tab){
      if(tab.props.label == '小规模增值税及附加税费申报表'){
        this.$refs.maintable.getList()
      }else if(tab.props.label == '增值税及附加税费申报表（小规模适用）附加资料（一）'){
        this.$refs.fbOne.getList()
      } else if(tab.props.label == '增值税及附加税费申报表（小规模适用）附加资料（二）'){
        this.$refs.fbTwo.getList()
      }else if(tab.props.label == '增值税减免税申报明细表'){
        this.$refs.fbThree.getList()
      }
    },
    printingNew(){
      report(this.param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    download(){
      exportReport(this.param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
}
</style>