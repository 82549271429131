<template>
  <!-- 增值税减免税申报明细表-->
  <div>
    <div class="big_box4">
      <div>
        <el-radio-group v-model="expStatus">
          <el-radio :label="true">使用公式</el-radio>
          <el-radio :label="false">不使用公式</el-radio>
        </el-radio-group>
      </div>
      <div class="top_title3">
        <h4>{{title}}</h4>
        <h5>小规模纳税人适用</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{$store.getters['user/comInfo'].comName}}</span></h6>
          <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{$store.getters['user/comInfo'].taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <div class="add" @click="handleAddItem(list,index)">
        <i class="iconfont icon-jiahao"></i>
      </div>
      <table class="content" cellspacing="0">
        <tr class="top_bg">
          <td colspan="8" class="center weight">一、减税项目</td>
        </tr>
        <tr class="top_bg">
          <td class="center" rowspan="2" style="width:25%">减税性质代码及名称</td>
          <td class="center" rowspan="2" style="width:6%">栏次</td>
          <td class="center" style="width:12%">期初余额</td>
          <td class="center" style="width:12%">本期发生额</td>
          <td class="center" style="width:12%">本期应抵减税额</td>
          <td class="center" style="width:12%">本期实际抵减税额</td>
          <td class="center" style="width:12%">期末余额</td>
        </tr>
        <tr class="top_bg">
          <td class="center">1</td>
          <td class="center">2</td>
          <td class="center">3=1+2</td>
          <td class="center">4≤3</td>
          <td class="center">5=3-4</td>
        </tr>
        <!-- <tr> -->
          <tr v-for="(item,index) in list" :key="index" class="position_tr">
            <td style="width:12%">
              <input style="padding:4px;width:60px" type="text" v-model="item.name">
              <!-- 减税性质代码 -->
              <jmxzdm type="gs_small_vat_js" v-model:id="item.jmxzdmId"></jmxzdm>
            </td>
            <td class="center">{{index + 1}}</td>
            <qzf-str-td :disabled="false" v-model:amount="item.qcye" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="item.bqfse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="item.bqydjse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="item.bqsjdjse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="item.qmye" @change="changeList()"></qzf-str-td>
            <span class="close" @click="handleDelItem(list,index)">
              <i class="iconfont icon-shanchu"></i>
            </span>
          </tr>
          <tr class="top_bg">
          <td colspan="8" class="center weight">二、免税项目</td>
        </tr>
        <tr class="top_bg">
          <td class="center" rowspan="2" style="width:24%; position: relative;">
            <div class="add2" @click="handleAddItem2(list2,index)">
              <i class="iconfont icon-jiahao"></i>
            </div>
            免税性质代码及名称</td>
          <td class="center" rowspan="2" style="width:6%">栏次</td>
          <td class="center" style="width:14%">免征增值税项目</td>
          <td class="center" style="width:14%">免税销售额扣除项目本期实际扣除金额</td>
          <td class="center" style="width:14%">扣除后免税销售额</td>
          <td class="center" style="width:14%">免税销售额对应的进项税额</td>
          <td class="center" style="width:14%">免税额</td>
        </tr>
        <tr class="top_bg">
          <td class="center">1</td>
          <td class="center">2</td>
          <td class="center">3=1-2</td>
          <td class="center">4</td>
          <td class="center">5</td>
        </tr>
        <tr>
          <td class="center">出口免税</td>
          <td class="center">1</td>
          <qzf-str-td :disabled="true"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="center">其中：跨境服务</td>
          <td class="center">2</td>
          <qzf-str-td :disabled="true"></qzf-str-td>
          <td class="center">----</td>
          <td class="center">----</td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <tr v-for="(item,index) in list3" :key="index">
          <td class="center">
            <jmxzdm type="gs_small_vat_ms" v-model:id="item.jmxzdmId"></jmxzdm>
          </td>
          <td class="center">3</td>
          <qzf-str-td :disabled="false" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false"  @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" @change="changeList()"></qzf-str-td>
        </tr>
        <tr v-for="(item,index) in list2" :key="index" class="position_tr">
          <td>
            <input style="padding:4px;width:60px" placeholder="" type="text" v-model="item.name">
            <!-- 减税性质代码 -->
            <jmxzdm type="gs_small_vat_ms" v-model:id="item.jmxzdmId"></jmxzdm>
          </td>
          <td class="center">{{index + 4}}</td>
          <qzf-str-td :disabled="false" v-model:amount="item.qcye" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="item.bqfse"  @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="item.bqydjse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="item.bqsjdjse" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="item.qmye" @change="changeList()"></qzf-str-td>
          <span class="close1" @click="handleDelItem2(list2,index)">
            <i class="iconfont icon-shanchu"></i> 
          </span>
        </tr>
      </table>
      <div class="save_box">
        <el-button @click="saveValues" type="primary" size="small" plain v-if="this.startAccountPeriod">保存</el-button>
        <qzf-button @success="saveValues" type="primary" size="small" plain v-else>保存</qzf-button>
      </div>
    </div>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'reduction',
  props: {
    title: String,
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
  },
  data() {
    return {
      expStatus: true,
      list:[],
      list2:[],
      list3:[{}],
      taxNo: '',
      comName: '',
      durationOfTaxTime:'',
      value:'',
      value1:'',
    }
  },
  created() {
    
  },
  methods: {
    // 公式
    changeList(){
      if(!this.expStatus){
        return
      }
      this.list.forEach(item => {
        item.bqydjse = String((item.qcye*1 + item.bqfse*1).toFixed(2))
        item.qmye = String((item.bqydjse*1 - item.bqsjdjse*1).toFixed(2))
        // item.kchmsxsehj = String((item.mzzzsxmxsehj*1 - item.bqsjkcjehj*1).toFixed(2))
      })
    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_small_vat',
          period:this.startAccountPeriod,
          itemName:'fb3',
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.durationOfTaxTime = res.data.data.time
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.list = res.data.data.info.filter(item => {
              return item.type == 1
            })
            this.list3 = res.data.data.info.filter(item => {
              return item.type == 3
            })
            this.list2 = res.data.data.info.filter(item => {
              return item.type == 2
            })
            this.list4 = res.data.data.info.filter(item => {
              return item.type == 4
            })
          }
        })
      }else{
        gsInfo({tableName: 'gs_small_vat',itemName:'fb3'}).then(res=>{
          if(res.data.data.info){
            this.durationOfTaxTime = res.data.data.time
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.list = res.data.data.info.filter(item => {
              return item.type == 1
            })
            this.list3 = res.data.data.info.filter(item => {
              return item.type == 3
            })
            this.list2 = res.data.data.info.filter(item => {
              return item.type == 2
            })
            this.list4 = res.data.data.info.filter(item => {
              return item.type == 4
            })
          }
        })
      }
    },
    // 删除一行
    handleDelItem(item, index) {
      item.splice(index, 1);
    },
    handleDelItem2(item, index) {
      item.splice(index, 1);
    },
    saveValues(){
      let listZ = [...this.list,...this.list2,...this.list3,...this.list4]
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_small_vat',
          itemName:'fb3',
          items:listZ,
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_small_vat',
          itemName:'fb3',
          items:listZ
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    },
    handleAddItem(item) {
      item.push({
        type: 1,
        jmxzdm:"",
        // name: "", //产品名称
        qcye:"",
        bqfse:"",
        bqydjse:"",
        bqsjdjse:"",
        qmye:"",
      })
    },
    handleAddItem2(item) {
      item.push({
        type:2,
        jmxzdm:"",
        name: "",
        qcye:"",
        bqfse:"",
        bqydjse:"",
        bqsjdjse:"",
        qmye:"",
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  } 
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h4{
    font-size: 20px;
    color: #333;
    font-weight: 600;
    text-align: center;
  }
  h5{
    font-size: 16px;
    color: #333;
    font-weight: normal;
    text-align: center;
    margin-top: 10px;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    h6{
      float: left;
      width: 33.33%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2  ){
      padding-top: 10px;
    }
  }
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
.add {
  position: absolute;
  top: 180px;
  left: 10px;
  cursor: pointer;
  i {
    color: $color-main;
    font-weight: 900;
    font-size: 20px;
  }
}
.add2 {
  position: absolute;
  left: -23px;
  font-weight: 900;
  font-size: 20px;
  cursor: pointer;
  i {
    color: $color-main;
    font-weight: 900;
    font-size: 20px;
  }
}
.position_tr{
  position: relative;
  .close,.close1 {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    margin-left: 5px;
    font-size: 20px;
    color: #ff1818;
    padding-top: 6px;
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
</style>
